import React, { useEffect, useState, useContext, useRef } from 'react';
import { UserContext } from '../../../context/userContext';
import * as Constants from '../../../constants';
import Footer from '../../includes/Footer/Footer';
import Header from '../../includes/Header/Header';
import Menu from '../../includes/Menu/Menu';
import './createNewSearch.css';

import { API, deleteToken, getToken, setToken } from '../../../http-common';
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

const SIN_GERENTE = '88860095';

const CreateNewSearch = () => {
    const { userData, setUserData } = useContext(UserContext)

    const [show, setShow] = useState(false);
    const [showError, setShowError] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleShowErrorClose = () => setShowError(false);
    const [errorMessage, setErrorMessage] = useState('')
    const [vacancies, setVacancies] = useState([]);
    const [departaments, setDepartaments] = useState([]);
    const [departaments_intranet, setDepartaments_intranet] = useState([]);
    const [seniorities, setSeniorities] = useState([]);
    const [directors, setDirectors] = useState([]);
    const [managements, setManagements] = useState([]);

    const [gerenteVisible, setGerenteVisible] = useState(0);
    const [costCenter, setCostCenter] = useState('');
    const [area, setArea] = useState('');
    const [sector, setSector] = useState('');



    const [started_by, setStarted] = useState(userData.id)
    const [report_to, setGerente] = useState(0)
    const [director_id, setDirector] = useState(0)
    const [position_name, setPosition] = useState('')
    const [seniority_id, setSeniority] = useState(0)
    const [description_task, setDescription] = useState('')
    const [profile_description, setProfile] = useState('')
    const [vacancy_reason, setVacancy] = useState(0)
    const [idDepartament, setIdDepartament] = useState('')
    const [id_departament_intranet, setId_departament_intranet] = useState('')
    const [corporate, setCorporate] = useState('0')
    const [intranet, setIntranet] = useState('0')

    //Variables de validacion de error

    const [report_toError, setGerenteError] = useState(false)
    const [director_idError, setDirectorError] = useState(false)
    const [position_nameError, setPositionError] = useState(false)
    const [seniority_idError, setSeniorityError] = useState(false)
    const [description_taskError, setDescriptionError] = useState(false)
    const [profile_descriptionError, setProfileError] = useState(false)
    const [vacancy_reasonError, setVacancyError] = useState(false)
    const [idDepartamentError, setIdDepartamentError] = useState(false)
    const [corporateError, setCorporateError] = useState(false)
    const [intranetError, setIntranetError] = useState(false)

    //Variables de mensaje de error

    const [report_toWarning, setGerenteWarning] = useState('')
    const [director_idWarning, setDirectorWarning] = useState('')
    const [position_nameWarning, setPositionWarning] = useState('')
    const [seniority_idWarning, setSeniorityWarning] = useState('')
    const [description_taskWarning, setDescriptionWarning] = useState('')
    const [profile_descriptionWarning, setProfileWarning] = useState('')
    const [vacancy_reasonWarning, setVacancyWarning] = useState('')
    const [idDepartamentWarning, setIdDepartamentWarning] = useState('')
    const [corporateWarning, setCorporateWarning] = useState('')
    const [intranetWarning, setIntranetWarning] = useState('')
    const [loading, setLoading] = useState(false)
    const formInitial = [{
        'cost_center':  costCenter,
        'area': area,
        'started_by': started_by,
        'report_to': report_to,
        'director_id': director_id,
        'position_name': position_name,
        'seniority_id': seniority_id,
        'description_task': description_task,
        'profile_description': profile_description,
        'vacancy_reason': vacancy_reason,
        'id_departament': idDepartament,
        'id_departament_intranet': id_departament_intranet,
        "status": 2,
        "corporate": '0',
        "intranet": '0'
    }];

    const [formData, setFormData] = useState(formInitial)
    const role = userData.role_id;

    //ver si las llamadas a servicios luego se pasan a otro archivo para poder importar de varios componentes
    function getVacancies() {
        API.get('/positions/vacancies')
            .then(function (response) {
                if (response.data.error === 0) {
                    setVacancies(response.data.data)
                } else {
                    //todo
                }
            })
    }

    function getDepartaments() {
        API.get('/positions/departaments')
            .then(function (response) {
                if (response.data.error === 0) {
                    setDepartaments(response.data.data)
                } else {
                    //todo
                }
            })
    }
    function getDepartamentsIntranet() {
        API.get('/positions/departaments_intranet')
            .then(function (response) {
                if (response.data.error === 0) {
                    setDepartaments_intranet(response.data.data)
                } else {
                    //todo
                }
            })
    }

    function getSeniorities() {
        API.get('/employees/seniorities')
            .then(function (response) {
                if (response.data.error === 0) {
                    setSeniorities(response.data.data)
                } else {
                    //todo
                }
            })
    }

    function getDirectors() {
        API.get('/employees/directors')
            .then(function (response) {
                if (response.data.error === 0) {
                    setDirectors(response.data.data)
                } else {
                    //todo
                }
            })
    }

    function getManagements() {
        API.get('/employees/managements')
            .then(function (response) {
                if (response.data.error === 0) {
                    setManagements(response.data.data)
                } else {
                    //todo
                }
            })
    }

    const handleShowError = (message) => {
        setErrorMessage(message)
        setShowError(true);
        
    }

    //truncado objeto en formData . ToDo bindeo de form y validacion
    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        const updatedFormData = {
            ...formData[0],
            cost_center: costCenter,
        }
        try{
            if(checkEmpty() === 0){
                API.post('/positions/create', updatedFormData)
                .then(function (response) {
                 
                  if (response.data.error === 1) {
                    setLoading(false)
                    handleShowError(response.data.message)
                  } else {
                    handleShow()
                    setLoading(false)
                  }
                })
            }
           
        }
        catch(error){
         throw new Error(error) 
        }
    }
  
    const handleSubmitLater = async (e) => {
        //    API.post('/positions/create', formData)
        //       .then(function (response) {
        //         /*if (response.data.error === 0) {

        //         } else {
        //           //todo
        //         }*/
        //       })

    }
    const searchCostCenter = (data, value) => {
        return data.find(d => d.id.toString() == value.toString())
    }

    useEffect(() => {
        updateFormData('cost_center', costCenter)
    }, [costCenter])

    
    useEffect(() => {
        updateFormData('area', area);
    }, [area]);

    const checkvalue = (nameInput, value) => {
        if (nameInput === 'report_to') {
            setCostCenter([])
            setSector('')
            setGerente(value)
            let item = searchCostCenter(managements, value)
            if (typeof item !== 'undefined') {
                setCostCenter(item.cost_center)
                setSector(item.sector)
                setArea(item.area)
            }

            if (value == SIN_GERENTE) {
                item = searchCostCenter(directors, director_id)
                if (typeof item !== 'undefined') {
                    setCostCenter(item.cost_center)
                    setSector(item.sector)
                }
            }
            
        } else if (nameInput === 'director_id') {
            setDirector(value)
            if (report_to === SIN_GERENTE) {
                setCostCenter([])
                setSector('')
                const item = searchCostCenter(directors, value)
                if (typeof item !== 'undefined') {
                    setCostCenter(item.cost_center )
                    setSector(item.sector)
                }
            }

            if (value.toString() === '0') {
                const item = searchCostCenter(managements, report_to)
                if (typeof item !== 'undefined') {
                    setCostCenter(item.cost_center)
                    setSector(item.sector)
                    setArea(item.area)
                }
            }
        } else if (nameInput === 'position_name') {
            setPosition(value)
        } else if (nameInput === 'seniority_id') {
            setSeniority(value)
        } else if (nameInput === 'description_task') {
            setDescription(value)
        } else if (nameInput === 'profile_description') {
            setProfile(value)
        } else if (nameInput === 'vacancy_reason') {
            setVacancy(value)
        } else if (nameInput === 'corporate') {
            value = value ? '1' : '0';
            setCorporate(value)
        }
        else if (nameInput === 'id_departament') {
            setIdDepartament(value)
        }
        else if (nameInput === 'intranet') {
            value = value ? '1' : '0';
            setIntranet(value)
        } else if (nameInput === 'id_departament_intranet') {
            setId_departament_intranet(value)
        }

        updateFormData(nameInput, value)
    }

    const checkEmpty = () => {
        let flag = 0;
        if (report_to == 0) {
            flag++
            setGerenteError(true)
            setGerenteWarning('Seleccione un Gerente')
        } else {
            setGerenteError(false)
            setGerenteWarning('')
        }

        if (director_id === 0) {
            flag++
            setDirectorError(true)
            setDirectorWarning('Seleccione un Director')
        } else {
            setDirectorError(false)
            setDirectorWarning('')
        }

        if (position_name.length === 0) {
            flag++
            setPositionError(true)
            setPositionWarning('El campo no puede estar vacío')
        } else if (position_name.length > 0 && position_name.length < 4) {
            flag++
            setPositionError(true)
            setPositionWarning('Debe tener mas de 4 caracteres')
        } else {
            setPositionError(false)
            setPositionWarning('')
        }

        if (seniority_id === 0) {
            flag++
            setSeniorityError(true)
            setSeniorityWarning('Selecione una opcion')
        } else {
            setSeniorityError(false)
            setSeniorityWarning('')
        }

        if (description_task.length === 0) {
            flag++
            setDescriptionError(true)
            setDescriptionWarning('El campo no puede estar vacío')
        } else if (description_task.length > 0 && description_task.length < 4) {
            flag++
            setDescriptionError(true)
            setDescriptionWarning('Debe tener mas de 10 caracteres')
        } else {
            setDescriptionError(false)
            setDescriptionWarning('')
        }

        if (profile_description.length === 0) {
            flag++
            setProfileError(true)
            setProfileWarning('El campo no puede estar vacío')
        } else if (profile_description.length > 0 && profile_description.length < 4) {
            flag++
            setProfileError(true)
            setProfileWarning('Debe tener mas de 10 caracteres')
        } else {
            setProfileError(false)
            setProfileWarning('')
        }

        if (vacancy_reason === 0) {
            flag++
            setVacancyError(true)
            setVacancyWarning('Selecione una opcion')
        } else {
            setVacancyError(false)
            setVacancyWarning('')
        }

        if (!['0', '1'].includes(corporate.toString())) {
            flag++
            setCorporateError(true)
            setCorporateWarning('Opción inválida')
        } else {
            setCorporateError(false)
            setCorporateWarning('')
        }
        if (!['0', '1'].includes(intranet.toString())) {
            flag++
            setIntranetError(true)
            setIntranetWarning('Opción inválida')
        } else {
            setIntranetError(false)
            setIntranetWarning('')
        }
        setLoading(false)
        return flag
    }


    const updateFormData = (nameInput, value) => {
        const newForm = formData.map((item) => {
            const updatedItem = { ...item, [nameInput]: value, };
            return updatedItem;
        });
        setFormData(newForm);
    }
    
    useEffect(() => {
        getVacancies();
        getDepartaments()
        getDepartamentsIntranet()
        getSeniorities();
        getDirectors();
        getManagements();
    }, []);

    const handleClick = e => {
        updateFormData('status', 2)
        handleSubmitLater()
    }
    return (
        <>
            <Header />
            <Menu />
            <div className="content-nuevo">
                <div className="nuevo">
                    <span className="titulo">NUEVA BÚSQUEDA</span>
                    <div className="formulario newsearch">
                        <form onSubmit={handleSubmit}>
                            <span className="sesion">Iniciada por <b>{userData.username}</b></span>
                            <hr />
                            <span className="item">e-mail <span>{userData.email}</span></span>
                            <hr />
                            {/*<div className="reportara">
                                <span className="item">¿Reportará a otra persona?</span>
                                <div className="content-input">
                                    <input type="radio" className="reportar" onChange={(e) => setGerenteVisible(e.target.value)} id="si" name="reportar" value={1} checked={gerenteVisible == 1 ? 'checked' : ''}/>
                                    <label htmlFor="si">SI</label>
                                </div>
                                <div className="content-input">
                                    <input type="radio" className="reportar" onChange={(e) => setGerenteVisible(e.target.value)} id="no" name="reportar" value={0} checked={gerenteVisible == 1 ? '' : 'checked'} />
                                    <label htmlFor="no">NO</label>
                                </div>
                            </div>*/}
                            <div className="datos-him row">
                                <div className={`col-md-6 content-datos ${gerenteVisible == 1 ? "" : ""}`}>
                                    <span className="item">Nombre y apellido del HM</span>
                                    <select value={report_to} name="report_to" onChange={(e) => checkvalue(e.target.name, e.target.value)} id="gerente">
                                        <option value="0">Gerente</option>
                                        {managements.map((item, i) => <option key={i} value={item.id}>{item.username}</option>)}
                                    </select>
                                    <div className={`alert alert-danger mt-3 ${report_toError == 1 ? "" : "d-none"}`}>{report_toWarning}</div>
                                </div>
                                <div className="col-md-6 content-datos">
                                    <span className="item">Director que aprueba la vacante</span>
                                    <select value={director_id} name="director_id" onChange={(e) => checkvalue(e.target.name, e.target.value)} id="director">
                                        <option value="0">Director</option>
                                        {directors.map((item, i) => <option key={i} value={item.id}>{item.username}</option>)}
                                    </select>
                                    <div className={`alert alert-danger mt-3 ${director_idError == 1 ? "" : "d-none"}`}>{director_idWarning}</div>
                                </div>
                            </div>
                            <hr />
                            <span className="item">Centro de costos asociado a la vacante <span>{costCenter}</span></span>
                            <br/>
                            <span className="item">Sector <span>{sector}</span></span>

                            <hr />
                            <div className="content-datos">
                                <span className="item">Nombre del puesto</span>
                                <input value={position_name} name="position_name" onChange={(e) => checkvalue(e.target.name, e.target.value)} type="text" />
                                <div className={`alert alert-danger mt-3 ${position_nameError == 1 ? "" : "d-none"}`}>{position_nameWarning}</div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-md-6 content-datos">
                                    <span className="item">Seniority</span>
                                    <select value={seniority_id} name="seniority_id" onChange={(e) => checkvalue(e.target.name, e.target.value)} id="seniority_id">
                                        <option value="0">Seleccione una opción</option>
                                        {seniorities.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)}
                                    </select>
                                    <div className={`alert alert-danger mt-3 ${seniority_idError == 1 ? "" : "d-none"}`}>{seniority_idWarning}</div>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-md-6 content-datos">
                                    <span className="item">Descripción de las tareas a realizar</span>
                                    <textarea onChange={(e) => checkvalue(e.target.name, e.target.value)} value={description_task} name="description_task" id="description_task" cols="30" rows="10"></textarea>
                                    <div className={`alert alert-danger mt-3 ${description_taskError == 1 ? "" : "d-none"}`}>{description_taskWarning}</div>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-md-6 content-datos">
                                    <span className="item">Descripción del perfil requerido: estudios, experiencia previa, etc.</span>
                                    <textarea onChange={(e) => checkvalue(e.target.name, e.target.value)} value={profile_description} name="profile_description" id="profile_description" cols="30" rows="10"></textarea>
                                    <div className={`alert alert-danger mt-3 ${profile_descriptionError == 1 ? "" : "d-none"}`}>{profile_descriptionWarning}</div>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-md-6 content-datos">
                                    <span className="item">La vacante se genera a partir de:</span>
                                    <select onChange={(e) => checkvalue(e.target.name, e.target.value)} value={vacancy_reason} name="vacancy_reason" id="vacancy_reason">
                                        <option value="0">Selecione una opcion</option>
                                        {vacancies.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)}
                                    </select>
                                    <div className={`alert alert-danger mt-3 ${vacancy_reasonError == 1 ? "" : "d-none"}`}>{vacancy_reasonWarning}</div>
                                </div>
                            </div>

                            {Constants.RRHH.includes(role) && (
                                <div className="row align-items-center">
                                    <div className="col-md-6 content-datos">
                                        <label for="corporate" className="d-flex align-items-center mb-0">
                                            <input onChange={(e) => checkvalue(e.target.name, e.target.checked)} id='corporate' value='1' name='corporate' type="checkbox" className='mr-2' />
                                            <span className="item pb-0">Publicar en sitio corporativo</span>
                                        </label>
                                        <div className={`alert alert-danger mt-3 ${corporateError ? "" : "d-none"}`}>{corporateWarning}</div>
                                    </div>
                                    {corporate === '1' && (
                                        <div className="col-md-6 content-datos">
                                            <span className="item">Departamentos:</span>
                                            <select onChange={(e) => checkvalue(e.target.name, e.target.value)} value={idDepartament} name="id_departament" id="id_departament">
                                                <option value="">Selecione una opcion</option>
                                                {departaments.map((item, i) => <option key={i} value={item.id_departament}>{item.departament}</option>)}
                                            </select>
                                            <div className={`alert alert-danger mt-3 ${idDepartamentError == 1 ? "" : "d-none"}`}>{idDepartamentWarning}</div>
                                        </div>
                                    )}

                                </div>
                            )}
                            <hr />

                            {Constants.RRHH.includes(role) && (
                                <div className="row align-items-center">
                                    <div className="col-md-6 content-datos">
                                        <label for="intranet" className="d-flex align-items-center mb-0">
                                            <input onChange={(e) => checkvalue(e.target.name, e.target.checked)} id='intranet' value='1' name='intranet' type="checkbox" className='mr-2' />
                                            <span className="item pb-0">Publicar en intranet de empleados de Balanz</span>
                                        </label>
                                        <div className={`alert alert-danger mt-3 ${intranetError ? "" : "d-none"}`}>{intranetWarning}</div>
                                    </div>
                                    {intranet === '1' && (
                                        <div className="col-md-6 content-datos">
                                            <span className="item">Departamentos:</span>
                                            <select onChange={(e) => checkvalue(e.target.name, e.target.value)} value={id_departament_intranet} name="id_departament_intranet" id="id_departament_intranet">
                                                <option value="">Selecione una opcion</option>
                                                {departaments_intranet.map((item, i) => <option key={i} value={item.id_departament_intranet}>{item.departament_intranet}</option>)}
                                            </select>
                                            <div className={`alert alert-danger mt-3 ${idDepartamentError == 1 ? "" : "d-none"}`}>{idDepartamentWarning}</div>
                                        </div>
                                    )}

                                </div>
                            )}
                            <div className="row">
                                <div className="col-md-6 botones">
                                    {
                                        loading ? <button id="guardas" disabled>GUARDANDO...</button> : <button onClick={handleSubmit} id="guardas">GUARDAR  Y ENVIAR A APROBACIÓN</button>
                                    }
                                    
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <Modal show={show} backdrop={'static'} className="modal_candidato" onHide={handleClose}>
                <Modal.Header>
                </Modal.Header>
                <Modal.Body><span>Búsqueda creada exitosamente</span></Modal.Body>
                <Modal.Footer>

                    <Link to='/busqueda'>
                        <Button variant="primary">
                            Ir a Búsquedas
                        </Button>
                    </Link>
                </Modal.Footer>
            </Modal>

            <Modal show={showError} backdrop={'static'} className="modal_candidato" onHide={handleShowErrorClose}>
                <Modal.Header>
                </Modal.Header>
                <Modal.Body><span>{errorMessage}</span></Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleShowErrorClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Footer />
        </>
    )
}

export default CreateNewSearch